import React, { memo, useEffect, useState } from 'react'

import { ComposableMap, Geographies, Geography } from 'react-simple-maps'
import { getChapters } from './components/slices/auth'
import { styled } from '@mui/system'
import { useDispatch } from 'react-redux'
import Tooltip from '@mui/material/Tooltip'
import { Link } from 'react-router-dom'
import SearchInput from './components/shared/search/SearchInput'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { useLocation } from 'react-router-dom'
import { FaAngleRight } from 'react-icons/fa'
import { Link as LinkRoll } from 'react-scroll'
import { motion } from 'framer-motion'

const MapChart = () => {
  const [activeCountry, setActiveCountry] = useState(null)
  const [chaptersMembers, setChaptersMembers] = useState([])
  const [countryData, setCountryData] = useState(null)
  const [countryName, setCountryName] = useState('')
  const [selectedTab, setSelectedTab] = useState(0)
  const dispatch = useDispatch()
  const location = useLocation()

  var homePage = location.pathname === '/'

  useEffect(() => {
    dispatch(getChapters(countryName)).then((res) => {
      setChaptersMembers(res?.payload)
    })
  }, [dispatch])
  const TextOnlyTooltip = styled(Tooltip)({
    arrow: {
      color: '#fff',
    },
    tooltip: {
      color: '#fff',
      backgroundColor: 'transparent',
    },
  })

  const searchDynamic = (e) => {
    e.preventDefault()
    if (e.target.value.trim() == '') {
      dispatch(getChapters(countryName)).then((res) => {
        setChaptersMembers(res?.payload)
      })
    } else {
      const filter = [
        ...chaptersMembers.filter((chapter) =>
          chapter.name
            .trim()
            .toLowerCase()
            .includes(e.target.value.trim().toLowerCase()),
        ),
      ]
      setChaptersMembers(filter)
    }
  }
  const path = location.pathname.split('/')
  const salamPaths = path.filter((p) => p.startsWith('chapters'))

  const [isOpen, setIsOpen] = useState(true)

  const handleClose = () => {
    setIsOpen(false)
    // if (window.innerWidth < 768) {
    //   window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth',
    //   })
    // }
  }
  const handleClick = () => {}
  return (
    <motion.div
      initial={{ translateY: 30 }}
      animate={{ translateY: 0 }}
      className="map__chart bg-[#E9EDF6]"
    >
      <Tabs onSelect={(index) => setSelectedTab(index)}>
        {salamPaths[0] == 'chapters' ? (
          ''
        ) : (
          <TabList>
            <div className="flex bg-[#E9EDF6] border border-[black] mb-[30px] mt-[28px] absolute w-[100%] max-[768px]:relative">
              <Tab>
                {' '}
                <div className="border-r border-black bg-[#F0F0F0]">
                  <button className="px-[26px] py-[18px]  text-[18px] uppercase font-bold max-[768px]:px-[12px] max-[768px]:text-[12px] max-[768px]:pt-[12px]">
                    Map
                  </button>
                </div>
              </Tab>
              <Tab>
                <div className="border-r border-black bg-[#F0F0F0]">
                  <button className="px-[26px] py-[18px] text-[18px] uppercase font-bold  max-[768px]:px-[12px] max-[768px]:text-[12px] max-[768px]:pt-[12px]">
                    List
                  </button>
                </div>
              </Tab>
              <SearchInput
                className="max-[768px]:py-[9px] max-[768px]:px-[10px]"
                bg="bg-[#F0F0F0]"
                placeholder="Search by country name"
                handleSearch={(e) => searchDynamic(e)}
                SearchClick={searchDynamic}
              />
            </div>
          </TabList>
        )}
        <TabPanel>
          <div className="test-map mt-[45px] md:mt-0">
            <ComposableMap>
              <Geographies geography="/features.json">
                {({ geographies }) =>
                  geographies.map((geo) => {
                    const data = chaptersMembers?.find(
                      (d) => d.name === geo.properties.name,
                    )
                    const isCurrentCountry = activeCountry === data?.name
                    return (
                      <React.Fragment key={geo.rsmKey}>
                        <Tooltip
                          onClose={handleClose}
                          className="tooltip1"
                          arrow
                          title={
                            <div className="tooltip-main arrow_box">
                              <div className="tooltip">
                                {data?.flag && (
                                  <img
                                    src={`${process.env.REACT_APP_DEV_API_URL}storage/${data?.flag}`}
                                    className="testT"
                                    alt=""
                                  />
                                )}
                                <span>{data?.name || geo.properties.name}</span>
                              </div>
                              <div className="see-more">
                                {location.pathname === '/' ? (
                                  <Link
                                    onClick={handleClose}
                                    to={`chapters/overview/${data?.id}`}
                                    className="text-[18px] text-[#343434] max-[768px]:text-[12px] flex items-center gap-x-[8px]"
                                  >
                                    See More <FaAngleRight />
                                  </Link>
                                ) : location.pathname === '/chapters' ? (
                                  <LinkRoll
                                    to="down"
                                    smooth={true}
                                    offset={50}
                                    duration={500}
                                  >
                                    <Link
                                      to={`overview/${data?.id}`}
                                      className="text-[18px] text-[#343434] max-[768px]:text-[12px] flex items-center gap-x-[8px]"
                                      onClick={handleClick}
                                    >
                                      See More <FaAngleRight />
                                    </Link>
                                  </LinkRoll>
                                ) : (
                                  <LinkRoll
                                    to="overview"
                                    smooth={true}
                                    offset={50}
                                    duration={500}
                                  >
                                    <Link
                                      to={`overview/${data?.id}`}
                                      className="text-[18px] text-[#343434] max-[768px]:text-[12px] flex items-center gap-x-[8px]"
                                      onClick={handleClick}
                                    >
                                      See More <FaAngleRight />
                                    </Link>
                                  </LinkRoll>
                                )}
                              </div>
                            </div>
                          }
                          open={isCurrentCountry}
                        >
                          <Geography
                            key={geo.rsmKey}
                            geography={geo}
                            onClick={() => {
                              setCountryData(data)
                              setActiveCountry(`${data?.name}`)
                              setIsOpen(false)
                            }}
                            style={{
                              default: {
                                fill: data === undefined ? '#ffff' : '#B5C5DA',
                                stroke: data === undefined ? '#000' : '#000',
                                strokeWidth: data === undefined ? '0.1' : '0.3',
                                outline: 'none',
                              },
                              hover: {
                                fill: data === undefined ? '#ffff' : '#254A7C',
                                outline: 'none',
                                stroke: data === undefined ? '#000' : 'black',
                                // stroke: data == undefined ? '#0d99ff' : '',
                                strokeWidth: data === undefined ? '0.1' : '1',
                              },
                              pressed: {
                                fill: data === undefined ? '#ffff' : '#254A7C',
                                stroke: data === undefined ? '#000' : '#000',
                                strokeWidth: data === undefined ? '0.1' : '1.3',
                                outline: 'none',
                              },
                            }}
                          />
                        </Tooltip>
                      </React.Fragment>
                    )
                  })
                }
              </Geographies>
            </ComposableMap>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="w-[100%] grid grid-cols-4 px-[50px] gap-10 pb-[270px] max-[768px]:grid-cols-1 max-[768px]:px-[0]  pt-">
            {chaptersMembers.length !== 0 ? (
              chaptersMembers?.map((chapters) => (
                <div className="flex items-center" key={chapters?.id}>
                  <img
                    className=" object-cover w-[100px] h-[52px]"
                    src={`${process.env.REACT_APP_DEV_API_URL}storage/${chapters?.flag}`}
                    alt=""
                  />
                  <Link
                    to={
                      homePage
                        ? `chapters/overview/${chapters?.id}`
                        : `overview/${chapters?.id}`
                    }
                    className="pl-[20px]"
                  >
                    {chapters?.name}
                  </Link>
                </div>
              ))
            ) : (
              <h4>Not found information</h4>
            )}
          </div>
        </TabPanel>
      </Tabs>
    </motion.div>
  )
}

export default memo(MapChart)
